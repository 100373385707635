<template lang='pug'>
table.main-table(v-if='resTable')
	tr
		th(title="Номер команды в рейтинге") #
		th.text-left Команда
		th(title="Заработанные очки") Очки
		th(title="Игр сыграно") И
		th(title="Победы") В
		th(title="Поражения") П
		th(title="Поражения") Партии
	tr(v-for="team in resTable" :class="{'active':team.active}")
		td {{ team.place }}
		td.text-left {{ team.name }}
		td {{ team.Оч }}
		td {{ team.И }}
		td {{ team.В }}
		td {{ team.П }}
		td {{ team.Пар }}
</template>

<script>
import { computed,onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
	name:'resultTable',
	setup(){
		const store = useStore()
		onMounted(store.dispatch('getResTable'))
		return {resTable: computed(() => store.getters.resTable)}
	}
}
</script>