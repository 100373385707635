<template lang='pug'>
h1 Турнирная таблица
resultTable
</template>

<script>
import resultTable from '@/components/resultTable'
export default {
	name: 'Home',
	components: {resultTable}
}
</script>
